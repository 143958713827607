import React, { useEffect, useRef, useState } from "react";
import { setCookie, getCookie } from "@Helpers/cookie";

export default function Popup() {
  // const isPopupVisable = getCookie("popup-xair") ? false : true;
  const [visibility, changeVisibility] = useState(true);
  const closePopup = () => {
    changeVisibility(!visibility);
    // setCookie("popup-xair", true, 30);
  };
  return (
    <div>
      {visibility && (
        <div>
          <div
            style={{
              width: "100%",
              height: "100%",
              minWidth: "100%",
              minHeight: "100%",
              backgroundColor: "black",
              zIndex: "5000",
              position: "fixed",
              opacity: "0.75",
            }}
          ></div>
          <div className="popup">
            <div className="popup_close" onClick={closePopup}></div>
            <div className="popup_header"></div>
            <div className="popup_title">Chcesz poznać xAir?</div>
            <div className="popup_content">
              Ładuj się w przyszłość i bądź na bieżąco z programem pilotażowym.
            </div>
            <div className="popup_button-container">
              <a href="https://www.nexity.io/xair">
                <button className="popup_button">Dowiedz się więcej</button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
