import React, { useEffect, useState } from "react";
import NavBar from "@Components/NavBar/NavBar";
import Home from "@Pages/Home/Home";
import About from "@Pages/About/About";
import InvestmentRelations from "@Pages/InvestmentRelations/InvestmentRelations";
import ChargeKnowlegde from "@Pages/ChargeKnowlegde/ChargeKnowlegde";
import InstallApp from "@Pages/InstallApp/InstallApp";
import DontSpeak from "@Pages/DontSpeak/DontSpeak";
import BigDipper from "@Pages/BigDipper/BigDipper";
import Future from "@Pages/Future/Future";
import SmokeOut from "@Pages/SmokeOut/SmokeOut";
import Footer from "@Pages/Footer/Footer";
import { CONSTANTS } from "@Constants/Constants";
import HomeCharger from "./HomeCharger/HomeCharger";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import Popup from "@Pages/PopUp/Popup";

function preserveScrollProgressOnResize() {
  const globalScrollTrigger = ScrollTrigger.create({});
  let progress = 0;

  ScrollTrigger.addEventListener("refreshInit", function () {
    progress = globalScrollTrigger.scroll() / ScrollTrigger.maxScroll(window);
  });

  ScrollTrigger.addEventListener("refresh", function () {
    globalScrollTrigger.scroll(progress * ScrollTrigger.maxScroll(window));
  });
}

export default function App() {
  const [color, setColor] = useState(CONSTANTS.color.primary);

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  };

  const switchLogotypeColor = (target) => {
    if (target.dataset.sectionType === CONSTANTS.sectionType.dark) {
      setColor(CONSTANTS.color.white);
    }
    if (target.dataset.sectionType === CONSTANTS.sectionType.light) {
      setColor(CONSTANTS.color.primary);
    }
  };

  useEffect(() => {
    let observer = new IntersectionObserver(onChange, options);

    function onChange(changes, observer) {
      changes.forEach((change) => {
        if (change.intersectionRatio > 0) {
          switchLogotypeColor(change.target);
        }
      });
    }
    const sections = document.querySelectorAll(".observe");
    sections.forEach((section) => observer.observe(section));

    preserveScrollProgressOnResize();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/favicon/favicon.svg"
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        ></link>
        <link rel="manifest" href="/favicon/site.webmanifest"></link>
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        ></link>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <title>Nexity - Dajemy dostęp</title>
        <meta name="title" content="Nexity - Dajemy dostęp" />
        <meta
          name="description"
          content="Dzięki darmowej aplikacji Nexity
        będziesz zawsze w zasięgu jednej
        z ponad 100 000 stacji ładowania
        w Europie."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nexty.io/" />
        <meta property="og:title" content="Nexity - Dajemy dostęp" />
        <meta
          property="og:description"
          content="Dzięki darmowej aplikacji Nexity
        będziesz zawsze w zasięgu jednej
        z ponad 100 000 stacji ładowania
        w Europie."
        />
        <meta property="og:image" content="https://nexity.io/images/X.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nexity.io/" />
        <meta property="twitter:title" content="Nexity - Dajemy dostęp" />
        <meta
          property="twitter:description"
          content="Dzięki darmowej aplikacji Nexity
        będziesz zawsze w zasięgu jednej
        z ponad 100 000 stacji ładowania
        w Europie."
        />
        <meta
          property="twitter:image"
          content="https://nexity.io/images/X.png"
        />
        <meta name="author" content="www.pureinteractive.pl" />
      </Helmet>
      <main id="main">
        <Popup></Popup>
        <NavBar logotypeColor={color} />
        <Home></Home>
        <About></About>
        <InvestmentRelations />
        <DontSpeak />
        <Future></Future>
        <InstallApp />
        <BigDipper></BigDipper>
        <ChargeKnowlegde />
        <SmokeOut />
        <Footer></Footer>
      </main>
    </>
  );
}
