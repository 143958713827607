import React, { useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { activatePill } from "../../../utils";

const sel = { 
  chargeHere: '.charge-here',
  pills: '.navigation-pills'
}

function handleAnimation() {
  ScrollTrigger.matchMedia({
    "(min-width: 1024px)": function() {
      const tl = gsap.timeline({
          scrollTrigger: {
              trigger: sel.chargeHere,
              start: 'top top',
              end: "+=100%",
              pin: false,
              scrub: true
          },
      });      
      gsap.set(sel.pills, { opacity: 0, visibility: 'hidden' })
      tl.fromTo(sel.pills, { opacity: 0, visibility: 'hidden' }, { opacity: 1, visibility: 'visible' });
    }
  })
}

export default function Pills({color, isOpen, setOpen}) {
  const [loading, setLoading] = useState(false);
  const [sectionAnchors, setsectionAnchors] = useState([]);
  const menu = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    handleAnimation();
    if(window.location.hash) {
      setLoading(true);

      setTimeout(() => {
        navigate(`/${window.location.hash}`);
      }, 500);

      setTimeout(() => {
        setLoading(false);
        activatePill(window.location.hash.substring(1))
      }, 1000);
    }
    setsectionAnchors(Array.from(document.querySelectorAll('[data-section-name]')));
    menu.current.addEventListener('click', () => {
      setOpen(false);
    })
  }, []);

  const loadSection = (e, section) => {
    //e.stopPropagation();
    setLoading(true);
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
    setOpen(false);

    setTimeout(() => {
      navigate(`/#${section}`);
    }, 500);

    setTimeout(() => {
      setLoading(false);
      activatePill(section)
    }, 1000);
  }

    return (
      <>
        <div className={`navigation-pills ${color} ${isOpen ? 'active' : ''}`} ref={menu}>
          {sectionAnchors.map(section => {
            return <div 
                      key={section.id}
                      className="navigation-pill"
                      data-section-pill={section.id}
                      onClick={(e) => loadSection(e, section.id)}
                    >
                      <span>{section.dataset.sectionName}</span>
                    </div>
          })}
        </div>
        <div className={`loading-area ${loading ? 'loading' : ''}`}>
          <img src="/images/X.png" alt="Nexity brand logo" />
        </div>
      </>
    )
}