import React, { useEffect } from "react";
import FbIcon from '@Icons/social-fb.svg';
import InstaIcon from '@Icons/social-ig.svg';
import TwitterIcon from '@Icons/social-tw.svg';
import YtIcon from '@Icons/social-yt.svg';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONSTANTS } from '@Constants/Constants';
import { activatePill } from "../../utils";

const sel = {
    footer: '.footer',
    footerTitle: '.footer_title',
    footerSubtitle: '.footer_subtitle',
    footerSocial: '.footer_social',
    footerAddress: '.footer_address',
    footerBorder: '.footer_info-border'
};

const beforeTransitionIn = {
    translateY: '40%',
    opacity: 0
};

const transitionIn = {
    translateY: '0%',
    opacity: 1
};

function handleAnimationMobile() {
    // Nothin
}

function handleAnimation() {
    const tl =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.footer,
            start: '-30% top',
        }
    });

    tl.fromTo(sel.footerTitle, beforeTransitionIn, { ...transitionIn, duration: 0.6  })
      .fromTo(sel.footerSubtitle, beforeTransitionIn, { ...transitionIn, duration: 0.6 }, '+=0.1')
      .fromTo(sel.footerBorder, {width: '0%'}, {width: '55%', duration: 1})
      .fromTo(sel.footerSocial, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.6')
      .fromTo(sel.footerAddress, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.6');
}

export default function Footer() {
    const { contact } = CONSTANTS.section;

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: sel.footer,
                start: '-1% top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.contact.id, CONSTANTS.section.contact.name)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.contact.id, CONSTANTS.section.contact.name)
                },
            }
        });
    }, []);

    return (
        <>
            <footer className="fullscreen footer observe" id={contact.id} data-section-name={contact.name} data-section-type={CONSTANTS.sectionType.dark}>
                <img className="footer_bg"
                    src="/images/stopka/stopka@0.5x.jpg"
                    srcSet="/images/stopka/stopka@0.5x.jpg 540w, /images/stopka/stopka@1x.jpg 1920w, /images/stopka/stopka@2x.jpg 2000w"
                    sizes="(max-width: 540px) 540px, (max-width: 1920px) 1920px, 100vw"
                    loading="lazy"
                />
                <div className="container">
                    <h2 className="footer_title">
                        Jesteś na miejscu!
                    </h2>       
                    <p className="footer_subtitle">
                        Dotarłeś do końca strony, która może stać się dla Ciebie początkiem przygody 
                        z&nbsp;elektromobilnością. Wejdź na nasz pokład już dziś tak, żebyś kiedyś mógł 
                        powiedzieć: “Byłem tam wcześniej. A nie mówiłem?”
                    </p>
                    <div className="footer_info">
                        <div className="footer_social">
                            <a
                                href="https://www.facebook.com/nexity.io/"
                                target="_blank"
                            >
                                <FbIcon></FbIcon>
                            </a>
                            <a  
                                href="https://twitter.com/nexity_global"
                                target="_blank"
                                >
                                <TwitterIcon></TwitterIcon>
                            </a>
                            <a
                                href="https://www.instagram.com/nexity.io/"
                                target="_blank"
                            >
                                <InstaIcon></InstaIcon>
                            </a>
                            <YtIcon></YtIcon>
                        </div>
                        <span className="footer_info-border"></span>
                        <div className="footer_address">
                            <p>
                                NEXITY GLOBAL S.A.                                   
                            </p>
                            <p>
                                ul. Bronowicka 130, 30-121 Kraków  
                            </p>
                            <a
                                href="tel:+48 18 542 84 28">
                                <p>
                                    SUPPORT: +48 18 542 84 28
                                </p>
                            </a>
                        </div>
                    </div>
                    <a 
                        className="footer_privacy-policy" 
                        href="https://nexity.io/privacy-policy"
                        target="_blank"
                    >
                        <p>Polityka prywatności <br /> &copy; 2020 NEXITY GLOBAL S.A. All rights reserved.</p>
                    </a>
                </div>
            </footer>
        </>
    );
}