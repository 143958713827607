import React, { useEffect } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Star from '@Icons/star.svg';
import { CONSTANTS } from '@Constants/Constants';

const sel = {
    section: '.big-dipper',
    bg: '.big-dipper_bg',
    title: '.big-dipper_title',
    subtitle: '.big-dipper_subtitle',
    btn: '.big-dipper .btn',
    container: '.container',
    scroll: '.big-dipper_scroll'
};

function handleAnimationMobile() {
    // const stars = gsap.utils.toArray('.star');
    // const starConnectors = gsap.utils.toArray('.star-connector');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sel.scroll,
            start: 'top top',
            end: '+=130%',
            scrub: 0.23,
            pin: true,
        }
    });

    // const tlAuto = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: sel.scroll,
    //         start: 'top top'
    //     }
    // });

    // tlAuto.set(starConnectors, {
    //     rotateZ: (index) => {
    //         const star = stars[index];
    //         const { x, y } = star.getBoundingClientRect();
    //         const prevStar = index != 6 ? stars[index + 1] : stars[3];
    //         const { 
    //             x: prevX,
    //             y: prevY 
    //         } = prevStar.getBoundingClientRect();
    //         const xDiff = x - prevX;
    //         const yDiff = y - prevY;
    //         let angle = Math.atan(yDiff / xDiff);
    //         if((yDiff < 0 && xDiff > 0) || (yDiff > 0 && xDiff > 0)) {
    //             angle = Math.PI + angle;
    //         }
    //         return `${angle}rad`;
    //     }
    // }).fromTo(stars, { opacity: 0 }, { opacity: 1, delay: (index) => 0.2 * index, duration: 0.2})
    // .fromTo(starConnectors, { width: '0%' }, { 
    //     width: (index) => {
    //         const star = stars[index];
    //         const { x, y } = star.getBoundingClientRect();
    //         const prevStar = index != 6 ? stars[index + 1] : stars[3];
    //         const { 
    //             x: prevX,
    //             y: prevY 
    //         } = prevStar.getBoundingClientRect();
    //         const xDiff = x - prevX;
    //         const yDiff = y - prevY;
    //         const width = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));

    //         return `${width}px`;
    //     },
    //     duration: 0.2,
    //     delay: (index) => 0.2 * index
    // }, '-=0.9');

    // tl.fromTo(sel.bg, { opacity: 0.8}, { opacity: 1, duration: 0.6 })
    // .fromTo(sel.title, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=1.3')
    // .fromTo(sel.section, { x: '0'}, { x: '-100vw'})
    // .fromTo(sel.subtitle, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.4')
    // .fromTo(sel.btn, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.4');

    tl.fromTo(sel.section, { x: '0'}, { x: '-100vw'});
}
  
function handleAnimation() {
    const stars = gsap.utils.toArray('.star');
    const starConnectors = gsap.utils.toArray('.star-connector');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sel.section,
            start: 'top top',
            end: '+=100%',
            scrub: 0.23,
            pin: true
        }
    });

    const tlAuto = gsap.timeline({
        scrollTrigger: {
            trigger: sel.section,
            start: 'top top'
        }
    });

    tlAuto.set(starConnectors, {
        rotateZ: (index) => {
            const star = stars[index];
            const { x, y } = star.getBoundingClientRect();
            const prevStar = index != 6 ? stars[index + 1] : stars[3];
            const { 
                x: prevX,
                y: prevY 
            } = prevStar.getBoundingClientRect();
            const xDiff = x - prevX;
            const yDiff = y - prevY;
            let angle = Math.atan(yDiff / xDiff);
            if((yDiff < 0 && xDiff > 0) || (yDiff > 0 && xDiff > 0)) {
                angle = Math.PI + angle;
            }
            return `${angle}rad`;
        }
    }).fromTo(stars, { opacity: 0 }, { opacity: 1, delay: (index) => 0.2 * index, duration: 0.2})
    .fromTo(starConnectors, { width: '0%' }, { 
        width: (index) => {
            const star = stars[index];
            const { x, y } = star.getBoundingClientRect();
            const prevStar = index != 6 ? stars[index + 1] : stars[3];
            const { 
                x: prevX,
                y: prevY 
            } = prevStar.getBoundingClientRect();
            const xDiff = x - prevX;
            const yDiff = y - prevY;
            const width = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));

            return `${width}px`;
        },
        duration: 0.2,
        delay: (index) => 0.2 * index
    }, '-=0.9');

    tl.fromTo(sel.bg, { opacity: 0.8}, { opacity: 1, duration: 0.6 })
    .fromTo(sel.title, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=1.3')
    .fromTo(sel.subtitle, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.4')
    .fromTo(sel.btn, { opacity: 0 }, { opacity: 1, duration: 0.6 }, '-=0.4');
}

export default function BigDipper() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
    }, []);

    return (
        <>
            <div className="big-dipper_scroll">
                <section className="fullscreen big-dipper observe chargersObserver" data-section-type={CONSTANTS.sectionType.dark}>
                    <img className="big-dipper_bg"
                        src="/images/wielki_woz/wielki_woz@0.5x.jpg"
                        srcSet="/images/wielki_woz/wielki_woz@0.5x.jpg 540w, /images/wielki_woz/wielki_woz@1x.jpg 1920w, /images/wielki_woz/wielki_woz@2x.jpg 2000w"
                        sizes="(max-width: 540px) 540px, (max-width: 1920px) 1920px, 100vw"
                        loading="lazy"
                    />
                    <img className="big-dipper_bg--mobile"
                        src="/images/big_dipper-mobile.jpg"
                        loading="lazy"
                    />
                    <div className="galactic">
                        {Array.from({length:7}).map((_, index) => (
                                <Star key={index}></Star>
                        ))}
                        {Array.from({length:7}).map((_, index) => (
                            <span key={'connector' + index} className="star-connector"></span>
                        ))}
                    </div>
                    <div className="container">
                        <h2 className="big-dipper_title">
                            Wielki wóz energii
                        </h2>       
                        <p className="big-dipper_subtitle">
                        Gdy elektromobilność to za mało... <br />
                        Razem z&nbsp;naszymi partnerami mamy dla Ciebie <br />
                        fotowoltaikę, magazyny energii i&nbsp;wiele więcej!
                        </p>
                        <button className="btn btn--primary">
                            <a href="https://columbusenergy.pl/" target="_blank">Dowiedz się więcej</a>
                        </button>
                    </div>
                </section>
            </div>
        </>
    );
}