import React from "react";

const features = [
    { counter: 1, description: "intuicyjna aplikacja" },
    { counter: 100000, description: "stacji ładowania" },
    { counter: 20, description: "krajów w zasięgu" },
];
const numberSpaceSeparator = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export default function Features() {
    return (
        <div className="features">
            {features.map(({counter, description}, index) => {
                return <div 
                            key={`feature-${index}`}
                            className="feature"
                        >
                            <div className="feature_counter">
                                {numberSpaceSeparator(counter)}
                            </div>
                            <div className="feature_description">
                                {description}
                            </div>
                        </div>
            })}
        </div>
    )
}