import React, { useState, useRef, useEffect } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'gatsby'
import { CONSTANTS } from "../../constants/Constants";
import { activatePill, fit } from '../../utils';
import { navigate } from "@reach/router";

const sel = {
    partner: '.partner',
    partnerLogo: '.partner_logo',
    partnerContent: '.partner_first-col',
    partnerPar: '.partner_first-col p',
    partnerBtn: '.partner_first-col button',
    smokeOut: '.fullscreen.smoke-out',
    smokeTitle: '.smoke-out_content h2',
    smokeSubtitle: '.smoke-out_content p',
    smokeForm: '.smoke-out_cta',
    smokeContent: '.smoke-out_content',
    smoke: '.smoke',
    canvas: '.partner_logo'
};


function setPosition(dataset, [index, el]) {
    return `${el.dataset[dataset]}%`;
}

function setInitialPosition(...data) {
    return setPosition('initialPosition', data);
}

function setMiddlePosition(...data) {
    return setPosition('middlePosition', data);
}

const smokesDetails = [
    {
        name: 'smoke_1.png',
        y0: 150,
        y1: -10
    },
    {
        name: 'smoke_2.png',
        y0: 300,
        y1: 20
    },
    {
        name: 'smoke_3.png',
        y0: 120,
        y1: -20
    },
    {
        name: 'smoke_4.png',
        y0: 68,
        y1: -10
    },
    {
        name: 'smoke_5.png',
        y0: 110,
        y1: 0
    },
    {
        name: 'smoke_6.png',
        y0: 170,
        y1: -15
    },
    {
        name: 'smoke_7.png',
        y0: 50,
        y1: -5
    }
];

const beforeTransitionIn = {
    translateY: '40%',
    opacity: 0
};

const transitionIn = {
    translateY: '0%',
    opacity: 1
};

function handleAnimation() {

    const canvas = document.querySelector(sel.canvas);
    const context = canvas.getContext("2d");

    canvas.width = 0.6 * window.innerWidth;
    canvas.height = 0.4 * window.innerHeight;

    const frameCount = 24;
    const currentFrame = index => `/images/nexity-animation/${(index + 1).toString()}.png`;

    const images = []
    const airpods = {
        frame: 0
    };

    for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
    }
    

    images[0].onload = render;
    const cover = fit(false);
    const {
        offsetX, 
        offsetY, 
        width, 
        height
    } = cover(canvas.width, canvas.height, 1920, 1080, 1, 0.5, 0.55);

    function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[airpods.frame], offsetX, offsetY, width, height);
    }

    const smokes = gsap.utils.toArray(sel.smoke);

    gsap.set(smokes, { y: setInitialPosition});

    let logo = document.querySelector(sel.partnerLogo);
    // logo.remove();
    // document.body.appendChild(logo);

    gsap.set(sel.partnerLogo, { position: 'absolute', top: '35%', left: '40%', visibility: 'hidden' });


    const tlLogo = gsap.timeline({
        scrollTrigger: {
            trigger: sel.partner,
            start: '-70% top',
            end: '+=300%',
            scrub: 0.23,
        }
    })

    const tlPartner =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.partner,
            start: 'top top',
            end: '+=100%',
            scrub: 0.23,
            pin: true,
        }
    })

    const tlSmokes =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.smokeOut,
            start: '-60% top',
            end: '+=340%',
            scrub: 0.23,
            id: 'smokeOut'
        }
    });

    const tlTexts =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.smokeOut,
            start: "top top",
            end: "+=180%",
            scrub: 0.23,
            id: 'smoke-out-texts',
            pin:true
        }
    });

    tlLogo.set(sel.partnerLogo, { visibility: 'visible' })
           .fromTo(sel.partnerLogo, 1, { y: '-40vh', opacity: 0 }, { y: '0', opacity: 1})
           .to(sel.partnerLogo, { y: '-130vh', ease: 'none', duration: 1.5}, '+=0.965')
           .set(sel.partnerLogo, { visibility: 'hidden' });
    
    tlPartner.fromTo(sel.partnerPar, beforeTransitionIn, { ...transitionIn, delay: -0.5})
             .fromTo(sel.partnerBtn, beforeTransitionIn, transitionIn)
             .to(airpods, { frame: frameCount - 1, snap: "frame", onUpdate: render, duration: 3});

    tlSmokes.to(smokes, { y: setMiddlePosition, duration: 1 });
    
    tlTexts.fromTo(sel.smokeTitle, beforeTransitionIn, transitionIn)
            .fromTo(sel.smokeSubtitle, beforeTransitionIn, transitionIn)
            .fromTo(sel.smokeForm, beforeTransitionIn, transitionIn);
}

function handleAnimationMobile() {
    const smokes = gsap.utils.toArray(sel.smoke);
    smokes.length = 5;
    gsap.set(smokes, { y: setInitialPosition});

    const tlSmokes =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.smokeOut,
            start: '-20% top',
            end: '+=240%',
            scrub: 0.23,
            id: 'smokeOut'
        }
    });

    tlSmokes.to(smokes, { y: setMiddlePosition, duration: 1 });
}

export default function SmokeOut() {
    const inputRef = useRef(null);
    const [isFilled, fillInput] = useState(false);
    const { investment } = CONSTANTS.section;

    const checkLength = () => {
        if(inputRef.current.value != 0) {
            fillInput(true);
        }
        else {
            fillInput(false);
        }
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.matchMedia({
            "(min-width: 561px)": handleAnimation,
            "(max-width: 560px)": handleAnimationMobile
        });

        gsap.timeline({
            scrollTrigger: {
                trigger: sel.partner,
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.investment.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.investment.id);
                }
            }
        })

        gsap.timeline({
            scrollTrigger: {
                trigger: sel.smokeOut,
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.investment.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.investment.id);
                }
            }
        });
    }, []);

    return (
        <>
            <section className="fullscreen partner observe" data-section-type={CONSTANTS.sectionType.light}>
                <div id={investment.id} data-section-name={investment.name}></div>
                <div className="container">
                    <div className="partner_content">
                        <div className="partner_first-col">
                            <p>Partner pierwszego wyboru.<br />
                            Spółka notowana na głównym parkiecie<br />
                            Giełdy Papierów Wartościowych.</p>
                            <button className="btn btn--secondary" title="Relacje inwestorskie">
                                <a href="https://relacje.nexity.io/" target="blank">Relacje inwestorskie</a>
                            </button>
                        </div>
                        <div className="partner_second-col">
                            <img className="partner_logo-relative" src="/images/nexity-animation/25.png" alt="Nexity brand logo" />
                            <canvas className="partner_logo" id="partner_logo" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="fullscreen smoke-out observe" data-section-type={CONSTANTS.sectionType.light}>
                {smokesDetails.map(({y0, y1, y2}, index) => (
                    <img 
                        className="smoke" 
                        key={index} 
                        src={`images/smoke/smoke.png`}
                        data-initial-position={y0}
                        data-middle-position={y1}
                        data-end-position={y2}
                    ></img>
                ))}
                <div className="container">
                    <div className="smoke-out_content">
                        <h2>Spalaj stąd!</h2>
                        <p>Wciąż jesteś elektromobilnym sceptykiem?<br /> 
                           A może masz inną sprawę?
                        </p>
                        <p>
                            Napisz do nas, a my znajdziemy odpowiednie
                            argumenty<br /> lub rozwiązanie problemu.
                        </p>
                        <a 
                            className="smoke-out_cta btn btn--secondary"
                            href="https://v1.nexity.io/contact"
                            target="_blank"
                        >
                            Skontaktuj się z&nbsp;nami
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}