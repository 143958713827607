import React, { useEffect, useRef } from "react";
import { CONSTANTS } from "@Constants/Constants";

const sel = {
  wrapper: ".fullscreen.home",
  title: ".home_title",
  bg: ".home_bg",
  subtitle: ".home_subtitle",
  hand: ".charge-here",
};

export default function Home() {
  const videoHome = useRef(null);

  useEffect(() => {
    videoHome.current.play();
  }, []);

  return (
    <>
      <div
        className="fullscreen home observe"
        data-section-type={CONSTANTS.sectionType.dark}
      >
        <video
          className="home_bg"
          playsInline
          autoPlay
          muted
          loop
          ref={videoHome}
        >
          <source src="videos/bg-small.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <h2 className="home_title">Dajemy dostęp</h2>
          <p className="home_subtitle">
            Czary mary, jesteś naładowany! <br />
            Pojazdy elektryczne potrzebują infrastruktury i&nbsp;systemu. <br />
            My je mamy i&nbsp;chętnie się nimi podzielimy z&nbsp;klientami
            indywidualnymi oraz firmami.
          </p>
        </div>
      </div>
    </>
  );
}
