import React, { useState } from "react";
import Logo from "@Components/NavBar/Logo/Logo";
import Hamburger from "@Components/NavBar/Hamburger/Hamburger";
import Pills from "@Components/NavBar/Pills/Pills";

export default function NavBar({logotypeColor}) {
    const [isOpen, setOpen] = useState(false);

    return (
        <header className="main navbar">
            <Hamburger
                isClicked={isOpen} 
                onHamburgerClick={() => setOpen(!isOpen)}
            />
            <Logo logotypeColor={logotypeColor} />
            <nav>
                <Pills color={logotypeColor} isOpen={isOpen} setOpen={setOpen}/>
            </nav>
        </header>
    );
}