import React, { useEffect } from "react";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONSTANTS } from '@Constants/Constants';

export default function InvestmentRelations() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                const sectionTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".freedom",
                        start: "top top",
                        end: "+=70%",
                        scrub: 0.23,
                        pin: true,
                    },
                })
                sectionTl.fromTo(".freedom_heading", { y: '60px', opacity: 0, }, { opacity: 1, y: '0px', duration: 1 })
                         .fromTo(".freedom_paragraph", { y: '60px', opacity: 0, }, { opacity: 1, y: '0px', duration: 1 }, '-=0.2');
            }
        });
    }, [])

    return (
        <>
            <section className="fullscreen freedom observe businessObserver" data-section-type={CONSTANTS.sectionType.light}>
                <img className="freedom_bg"
                    src="/images/tinified/freedom.png"
                    loading="lazy"
                />
                <div className="container">
                    <div className="freedom_content">
                        <h2 className="freedom_heading">Wolność <span>ma <br />na imię Nexity</span></h2>
                        <p className="freedom_paragraph">Uwolnij się od cen paliw, korporacji <br />i poczucia obowiązku. Podaruj sobie niezależność. Podaruj sobie wolność.</p>
                    </div>
                </div>
            </section>
        </>
    )
}