import React from "react";

export default function Hamburger({isClicked, onHamburgerClick}) {

    return (
        <div
            onClick={onHamburgerClick}
            className={`hamburger ${isClicked ? 'active' : ''}`}
        >
            <span className="hamburger_line"></span>
            <span className="hamburger_line"></span>
            <span className="hamburger_line"></span>
        </div>
    )
}