import React from "react";
import { Link } from "gatsby"
import LogoMobile from "@Icons//logo-mobile.svg";

export default function Logo({logotypeColor}) {
    
    return(
        <>
            <Link to={'/'} className={`logo logo--desktop fill-${logotypeColor}`}>
            <svg id="Warstwa_1" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" viewBox="100 0 3350 1183">
                <title>Logo Nexity</title>
                <path  d="M514.67,268,286,302.2v-36H144.19v512H286V445.8l240.79-35.87V778.15H668.65v-512H539.09A165,165,0,0,0,514.67,268Z"/>
                <path  d="M723.4,522.59c0-160.33,118.55-269.16,261.39-269.16,157.42,0,255.56,116.6,255.56,268.19v36H869.15c1,67.05,50.53,107.86,117.58,107.86,46.64,0,91.34-18.46,110.78-61.22l126.32,29.15c-36.93,114.66-136,157.42-235.16,157.42C832.23,790.79,723.4,677.09,723.4,522.59Zm374.11-59.27C1090.71,411.81,1045,371,984.79,371c-66.08,0-107.86,46.64-114.66,92.31Z"/>
                <polygon  points="1930.97 391.41 1848.01 391.41 1848.01 266.06 2071.87 266.06 2071.87 778.15 1930.97 778.15 1930.97 391.41"/>
                <path  d="M1828.63,652.8a76.43,76.43,0,0,1-54-22.39L1665.25,520.77l-99.8,99.75L1700.25,755.7a76.44,76.44,0,0,0,54.12,22.46H1848V652.8Z"/>
                <path  d="M1828.85,391.41H1848V266.06h-93.6a76.43,76.43,0,0,0-54.16,22.5L1359.14,630.41a76.43,76.43,0,0,1-54,22.39h-19.74V778.15H1379a76.43,76.43,0,0,0,54.05-22.39l341.71-342A76.43,76.43,0,0,1,1828.85,391.41Z"/>
                <path  d="M1304.73,391.41a76.43,76.43,0,0,1,54,22.39l41.55,41.63,99.35-100.16-66.57-66.75A76.44,76.44,0,0,0,1379,266.06h-93.63V391.41Z"/>
                <rect  x="3065.98" y="266.17" width="125.02" height="125.02"/>
                <path  d="M2759.4,642.11H2748.7L2626.8,266.06H2126.62V391.41h143.57V778.15h140.9V391.41h122l129.16,386.74h27.21l-22.35,58.31c-10.69,27.21-26.24,36-53.45,36h-74.82v129.24h84.54c91.34,0,144.78-31.1,171-104l224.47-631.62H2883.46Z"/>
            </svg>
            </Link>
            <Link to={'/'} className="logo logo--mobile">
                <LogoMobile />
            </Link>
        </>
    )
}