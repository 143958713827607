import React, { useEffect } from "react";
import PlayStore from '@Components/MobileStores/PlayStore';
import AppleStore from '@Components/MobileStores/AppleStore';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Features from "@Components/Features/Features";
import { CONSTANTS } from '@Constants/Constants';
import { activatePill } from "../../utils";
import { navigate } from "@reach/router";

const sel = {
    scrollFirst: '.scroll.first',
    scrollContentFirst: '.scrollable-content.first',
    scrollContentSecond: '.scrollable-content.second',
    scrollSecond: '.scroll.second',
    othersContainer: '.fullscreen.others',
    othersTitle: '.others_title',
    othersSubtitle: '.others_subtitle',
    getIntoContainer: '.fullscreen.get-into',
    getIntoTitle: '.get-into_title',
    getIntoSubtitle: '.get-into_subtitle',
    businessContainer: '.fullscreen.business',
    businessTitle: '.business_title',
    businessSubtitle: '.business_subtitle',
    electromobilityContainer: '.fullscreen.electromobility',
    electromobilityTitle: '.electromobility_title',
    electromobilitySubtitle: '.electromobility_subtitle',
    electromobilityContent: '.electromobility_content',
    family: '.family',
    hand: '.charge-here',
    handContainer: '.charge-here .container',
    handTitleFirst: '.charge-here_title.first-part',
    handTitleSecond: '.charge-here_title.second-part',
    handSubtitle: '.charge-here_subtitle',
    handDownload: '.charge-here_download',
    handBg: '.charge-here_bg',
    handBgMobile: '.charge-here_bg--mobile',
    fixedLogo: '.fixed-logo',
    relativeLogo: '.relative-logo',
    relations: '.investments-relations',
    features: '.features',
    appPill: "[data-section-pill='app']",
    faqBtn: ".charge-here_btn",
};

function handleAnimationMobile() {
    const tlHand =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.hand,
            start: 'top top',
            end: '+=250%',
            pin:true,
            scrub: true,
        }
    });

    const xSectionFirstHorizontalTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollFirst,
            start: 'top top',
            scrub: true,
            pin: true,
            end: '+=200%',
        }
    });

    const xSectionSecondHorizontalTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollSecond,
            start: 'top top',
            scrub: true,
            pin: true,
            end: '+=200%',
        }
    });

    // tlHand.fromTo(sel.handTitleFirst, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', duration: 0.6 })
    //     .fromTo(sel.handContainer, { x: '0%' }, { x: '-50%', duration: 0.6 })
    //     .fromTo(sel.handBgMobile, { x: '0%' }, { x: '-50%', duration: 0.6 }, '-=0.6')
    //     .fromTo(sel.handTitleSecond, { opacity: 0, x: '-50%'}, { opacity: 1, x: '0%', duration: 1 } )
    //     .fromTo(sel.handSubtitle, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', duration: 1 },  '-=0.2')
    //     .fromTo(sel.handDownload, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', duration: 1 }, '-=1');

    tlHand.fromTo(sel.handContainer, { x: '0%' }, { x: '-50%', duration: 0.6 })
        .fromTo(sel.handBgMobile, { x: '0%' }, { x: '-50%', duration: 0.6 }, '-=0.6');

    xSectionFirstHorizontalTimeline.set('.home', { visibility: 'hidden' })
        .fromTo(sel.scrollContentFirst, { x: '0vw'}, { x: '-100vw', duration: 2.5});

    xSectionSecondHorizontalTimeline.fromTo(sel.scrollContentSecond, { x: '0vw' }, { x: '-100vw', duration: 2.5});
}

function handleAnimation() {
    const tlHand =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.hand,
            start: 'top top',
            end: '+=100%',
            pin:true,
            scrub: 0.23,
        }
    });

    const xSectionFirstHorizontalTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollFirst,
            start: 'top top',
            scrub: 0.23,
            pin: true,
            end: '+=400%',
        }
    });

    const xSectionFirstContentTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollFirst,
            start: '-70% top',
            scrub: 0.23,
            end: '+=50%',
        }
    });

    const xSectionSecondHorizontalTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollSecond,
            start: 'top top',
            scrub: 0.23,
            pin: true,
            end: '+=400%'
        }
    });

    const scaleXTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.scrollSecond,
            start: '5% top',
            end: '+=100%',
            scrub: 0.23,
        }
    });

    const featuresTimeline =  gsap.timeline({
        scrollTrigger: {
            trigger: sel.relations,
            start: 'top top',
            end: "+=70%",
            pin:true,
            scrub: 0.23,
        }
    });

    let content = document.querySelector('.electromobility_content');
    content.remove();
    document.body.appendChild(content);

    tlHand.fromTo(sel.handTitleFirst, 1, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%' })
    .fromTo(sel.handTitleSecond, 1, { opacity: 0, x: '-50%'}, { opacity: 1, x: '0%', delay: 0.2 })
    .fromTo(sel.handSubtitle, 1, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: 0.2})
    .fromTo(sel.handDownload, 1, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: -1 })
    .fromTo(sel.faqBtn, 1, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: -1 });

    xSectionFirstContentTimeline.set(sel.electromobilityContent, { position: 'fixed', bottom: '20%', left: '10%' }).fromTo(sel.electromobilityContent, { opacity: 0, zIndex: -1 }, { opacity: 1, zIndex: 1, duration: 0.6 })
    .fromTo(sel.electromobilityTitle, 0.6, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%'})
    .fromTo(sel.electromobilitySubtitle, 0.6, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: -0.3})

    xSectionFirstHorizontalTimeline.set('.home', { visibility: 'hidden', delay: -1 })
    .addLabel('electromobility-text-in', '<')
    .fromTo(sel.scrollContentFirst, {x: '0vw'}, { x: '-62vw', duration: 1})
    .addLabel('electromobility-scroll-left', '<')
    .fromTo(sel.fixedLogo, 1, { opacity: 0, scale: 0.5}, { opacity: 1, scale: 1})
    .addLabel('electromobility-text-hide', '<')
    .fromTo(sel.scrollContentFirst, { x: '-62vw'}, { x: '-162vw', duration: 0.8})
    .addLabel('family-scroll-left', '<')
    .fromTo(sel.electromobilityContent, { x: '0%', opacity: 1, zIndex: 1 }, { x: '-20%', opacity: 0, zIndex: -1, duration: 0.6}, '-=0.8')
    .set(sel.electromobilityContent, { visibility: 'hidden' })
    .fromTo(sel.othersTitle, 0.5, { opacity:0, y:'-3rem' }, { opacity:1, y:'0rem', delay: 0.3 })
    .fromTo(sel.othersSubtitle, 0.5, { opacity:0, y:'-3rem' }, { opacity:1, y:'0px', delay: -0.3 })
    .addLabel('charge-others-in', '<');
    

    xSectionSecondHorizontalTimeline.fromTo(sel.businessTitle, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', duration: 0.6})
        .fromTo(sel.businessSubtitle, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: -0.3, duration: 0.6})
        .addLabel('slide-left-business')
        .fromTo(sel.scrollContentSecond, { x: '0vw'}, { x: '-100vw', duration: 1 })
        .fromTo(sel.getIntoTitle, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', duration: 0.6})
        .fromTo(sel.getIntoSubtitle, { opacity: 0, y: '30%'}, { opacity: 1, y: '0%', delay: -0.3, duration: 0.6})
        // SECOND HORIZONTAL SCROLL END

    scaleXTimeline.fromTo(sel.fixedLogo, {scale: 1, translateY: '0%'},  { scale: 2.5, translateY: '-25%', duration: 3 });

    featuresTimeline
        .set(sel.fixedLogo, { visibility: 'hidden' })
        .set(sel.relativeLogo, { visibility: 'visible' })
        .fromTo(sel.features, { opacity: 0 }, { opacity: 1, duration: 0.6 })
}

export default function About() {
    //const [appPill, setAppPill] = useState(null);
    const { app, individual, business } = CONSTANTS.section;

    useEffect(() => {
        //setAppPill(document.querySelector('[data-section-pill="app"]'));
        gsap.registerPlugin(ScrollTrigger);
        document.querySelector(sel.scrollFirst).classList.add('horizontal');
        document.querySelector(sel.scrollSecond).classList.add('horizontal');

        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.appObserver',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.app.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.app.id)
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.individualObserver',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.individual.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.individual.id)
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.individualObserver2',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.individual.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.individual.id)
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.businessObserver',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.business.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.business.id)
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.businessObserver2',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.business.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.business.id)
                }
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.businessObserver3',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.business.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.business.id)
                }
            }
        });
    }, []);

    return ( 
        <>
            <section className="fullscreen charge-here observe appObserver" id={app.id} data-section-name={app.name} data-section-type={CONSTANTS.sectionType.dark}>
                <img className="charge-here_bg"
                    src="/images/apka/app.jpg"
                    loading="eager"
                />
                <img className="charge-here_bg--mobile"
                    src="/images/apka/apka_mobile.jpg"
                    loading="eager"
                />
                <div className="container">
                    <div className="charge-here_title-wrapper">
                        <h2 className="charge-here_title first-part">
                            Ładuj się tu 
                        </h2>
                        <h2 className="charge-here_title second-part">
                            i&nbsp;tam
                        </h2>
                    </div>             
                    <p className="charge-here_subtitle">
                        Dzięki darmowej aplikacji Nexity <br />
                        będziesz zawsze w&nbsp;zasięgu jednej <br />
                        z&nbsp;ponad <strong>100 000 stacji ładowania </strong><br />
                        w&nbsp;Europie.
                    </p>
                    
                    <button className="btn btn--primary charge-here_btn">
                        <a href="https://nexity.io/info/" target="_blank">Sprawdź jak</a>
                    </button>

                    <span className="charge-here_download">
                        <PlayStore />
                        <AppleStore />
                    </span>
                </div>
            </section>
            <div className="scroll first">
                <div className="scrollable-content first">
                    <section className="fullscreen electromobility observe individualObserver" id={individual.id} data-section-name={individual.name} data-section-type={CONSTANTS.sectionType.dark} >
                        {/* <div id={individual.id} data-section-name={individual.name}></div> */}
                        <img className="electromobility_bg"
                            src="/images/garaz/garaz@0.5x.jpg"
                            srcSet="/images/garaz/garaz@0.5x.jpg 540w, /images/garaz/garaz@1x.jpg 1920w, /images/garaz/garaz@2x.jpg 2000w"
                            sizes="(max-width: 540px) 540px, (max-width: 1920px) 1920px, 100vw"
                            loading="lazy"
                        />
                        <picture className="electromobility_bg--mobile">
                            <source media="(max-width: 1024px)" srcSet="/images/pod_domem/pod_domem@0.5x.jpg" />
                            <source media="(max-width: 1920px)" srcSet="/images/pod_domem/pod_domem@1x.jpg" />
                            <source media="(min-width: 1921px)" srcSet="/images/pod_domem/pod_domem@2x.jpg" />
                            <img src="/images/pod_domem/pod_domem@0.5x.jpg" alt="Nexity - Ładuj się w elektromobilność" loading="lazy" className="electromobility_bg--mobile-img"/>
                        </picture>
                        <div className="container">
                            <article className="electromobility_content">
                                <h2 className="electromobility_title">
                                    Ładuj się <br />
                                    w&nbsp;elektromobilność
                                </h2>             
                                <p className="electromobility_subtitle">
                                Masz pojazd elektryczny?
                                Warto mieć własną stację ładowania.&nbsp;<br />
                                My możemy Ci w&nbsp;tym pomóc. Dołącz do globalnej sieci Nexity.
                                </p>
                            </article>
                        </div>
                    </section>
                    <section className="family">
                        <picture className="family_bg">
                            <source media="(max-width: 1024px)" srcSet="/images/pod_domem/pod_domem@0.5x.jpg" />
                            <source media="(max-width: 1920px)" srcSet="/images/pod_domem/pod_domem@1x.jpg" />
                            <source media="(min-width: 1921px)" srcSet="/images/pod_domem/pod_domem@2x.jpg" />
                            <img src="/images/pod_domem/pod_domem@0.5x.jpg" alt="Nexity - Ładuj się w elektromobilność" loading="lazy" className="family_bg-img"/>
                        </picture>
                    </section>
                    <section className="fullscreen others observe individualObserver2" data-section-type={CONSTANTS.sectionType.dark}>
                        <picture className="others_bg">
                            <source media="(max-width: 1024px)" srcSet="/images/tinified/about3.jpg" />
                            <source media="(max-width: 1920px)" srcSet="/images/tinified/about3.png" />
                            <source media="(min-width: 1921px)" srcSet="/images/tinified/about3.png" />
                            <img src="/images/tinified/about3.jpg" alt="Nexity - Ładuj innych" loading="lazy" className="others_bg-img"/>
                        </picture>
                        <div className="container">
                            <h2 className="others_title">
                                Ładuj <br />innych
                            </h2>             
                            <p className="others_subtitle">
                                Podziel się energią! <br />
                                Zwłaszcza, jeśli masz jej za dużo <br />
                                i&nbsp;możesz na tym zarobić!
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="scroll second">
                <div className="scrollable-content second ">
                    <section className="fullscreen business observe businessObserver" id={business.id} data-section-name={business.name} data-section-type={CONSTANTS.sectionType.dark}>
                        <picture className="business_bg">
                            <source media="(max-width: 1024px)" srcSet="/images/laduj_biznes_stacja/laduj_biznes_stacja@0.5x.jpg?v=10" />
                            <source media="(max-width: 1920px)" srcSet="/images/laduj_biznes_stacja/laduj_biznes_stacja@1x.jpg?v=10" />
                            <source media="(min-width: 1921px)" srcSet="/images/laduj_biznes_stacja/laduj_biznes_stacja@2x.jpg?v=10" />
                            <img src="/images/laduj_sie_biznes/laduj_sie_biznes@0.5x.jpg?v=10" alt="Nexity - Ładuj swój biznes" loading="lazy" className="business_bg-img"/>
                        </picture>
                        <div className="container">
                            <h2 className="business_title">
                                Ładuj swój biznes
                            </h2>             
                            <p className="business_subtitle">
                                Przejdź ze swoją firmą na elektryczną stronę mocy. <br />
                                Wygodnie ładuj pojazdy firmowe lub pojazdy Twoich klientów.
                            </p>
                        </div>
                    </section>
                    <section className="fullscreen get-into observe businessObserver2" data-section-type={CONSTANTS.sectionType.dark}>
                        <picture className="get-into_bg">
                            <source media="(max-width: 1024px)" srcSet="/images/laduj_sie_biznes/laduj_sie_biznes@0.5x.jpg?v=10" />
                            <source media="(max-width: 1920px)" srcSet="/images/laduj_sie_biznes/laduj_sie_biznes@1x.jpg?v=10" />
                            <source media="(min-width: 1921px)" srcSet="/images/laduj_sie_biznes/laduj_sie_biznes@2x.jpg?v=10" />
                            <img src="/images/laduj_sie_biznes/laduj_sie_biznes@0.5x.jpg?v=10" alt="Nexity - Ładuj się w biznes stacji ładowania" loading="lazy" className="get-into_bg-img"/>
                        </picture>
                        <div className="container">
                            <h2 className="get-into_title">
                                Ładuj się
                                w&nbsp;biznes <br />stacji 
                                ładowania
                            </h2>             
                            <p className="get-into_subtitle">
                                Stacja paliw to za mało? <br />
                                Zrób krok naprzód i&nbsp;zacznij zarabiać na <br />
                                komercyjnym ładowaniu <br />
                                pojazdów elektrycznych.
                            </p>
                        </div>
                    </section>
                </div>
            </div>


            <section className="fullscreen investments-relations observe businessObserver3" data-section-type={CONSTANTS.sectionType.light}>
                <div className="container">
                    <img className="relative-logo" src="/images/X.png" alt="Nexity brand logo" />
                    <Features />
                </div>
            </section>

            <img className="fixed-logo" src="/images/X.png"></img>
        </>
    );
}